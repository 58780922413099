import React from 'react';
import { RouteFormatter } from '../../routes';
import SnifSection from '../../components/snif-section';
import SvgCheck from 'src/assets/svg/SvgCheck.svg';
import SvgCross from 'src/assets/svg/SvgCross.svg';

const MIN_SPOTS_APPEREANCES = 2;
const MIN_STATS_SHOWN = 3;
const MAX_STATS_SHOWN = 6;

const RenderStatus = ({ data, handleExplore, handleSubStatsClick }) => {
    const stats = [
        {
            title: 'dog parks of 1/2 acre or more',
            count: data?.moreThanHalfAcreSpotsCount || 0,
            type: 'Size',
        },
        {
            title: 'dog water parks',
            count: data?.swimmingSpotsCount || 0,
            link: `${RouteFormatter.waterListings()}`,
        },
        {
            title: 'fully fenced dog parks',
            count: data?.fullyFencedCount || 0,
            link: `${RouteFormatter.fencedListings()}`,
        },
        {
            title: 'dog hiking trails',
            count: data?.trailSpotsCount || 0,
            link: `${RouteFormatter.hikingListings()}`,
        },
        {
            title: 'dog fields',
            count: data?.fieldSpotsCount || 0,
            link: `${RouteFormatter.fieldsListings()}`,
        },
        {
            title: 'dog agility parks',
            count: data?.agilitySpotsCount || 0,
            link: `${RouteFormatter.agilityListings()}`,
        },
        {
            title: 'dog beaches',
            count: data?.beachSpotsCount || 0,
            link: RouteFormatter.listings({}),
            type: 'Beach',
        },
        {
            title: 'dog indoor spaces',
            count: data?.indoorSpotsCount || 0,
            link: RouteFormatter.listings({}),
            type: 'Indoor',
        },
    ];
    const filteredStats = stats.filter((s) => s.count >= MIN_SPOTS_APPEREANCES);
    const handleClick = (e, link, type) => {
        e.preventDefault();
        if (!link) {
            handleExplore(type);
            return;
        }
        if (type) {
            handleSubStatsClick(type);
            return;
        }
        window.open(link, '_blank');
    };

    return (
        <SnifSection>
            <div className="sniff-rent-private">
                <div className="snif-m1 snif-l1-pc snif-semibold mb-3">Why rent a private dog park with Sniffspot?</div>
                {filteredStats.length >= MIN_STATS_SHOWN && (
                    <div className="sniff-stats">
                        {filteredStats.slice(0, MAX_STATS_SHOWN).map((s, i) => (
                            <a
                                key={i}
                                href={s.link || RouteFormatter.listings({})}
                                onClick={(e) => handleClick(e, s.link, s.type)}
                            >
                                <div className="snif-l2 snif-xl-pc snif-bold text-primary mb-05">{s.count}</div>
                                <div className="snif-s2 snif-s1-pc snif-medium">{s.title}</div>
                            </a>
                        ))}
                    </div>
                )}
                <div className="rent-private-tbl">
                    <table>
                        <thead>
                            <tr>
                                <th>Features</th>
                                <th>Sniffspot</th>
                                <th>Public park</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="tbl-tl">Designed for private play</td>
                                <td className="text-center text-primary">
                                    <SvgCheck />
                                </td>
                                <td className="text-center text-danger tbl-tr">
                                    <SvgCross />
                                </td>
                            </tr>
                            <tr>
                                <td>Rentable by the hour</td>
                                <td className="text-center text-primary">
                                    <SvgCheck />
                                </td>
                                <td className="text-center text-danger">
                                    <SvgCross />
                                </td>
                            </tr>
                            <tr>
                                <td>Off leash options</td>
                                <td className="text-center text-primary">
                                    <SvgCheck />
                                </td>
                                <td className="text-center text-danger">
                                    <SvgCross />
                                </td>
                            </tr>
                            <tr>
                                <td className="tbl-bl">Very large and/or fully fenced options</td>
                                <td className="text-center text-primary">
                                    <SvgCheck />
                                </td>
                                <td className="text-center text-danger tbl-br">
                                    <SvgCross />
                                </td>
                            </tr>
                            <tr>
                                <td>Pricing</td>
                                <td className="text-center">$5 - $15 per dog per hour</td>
                                <td className="text-center">Free</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </SnifSection>
    );
};

export default RenderStatus;
