import React, { useEffect, useState } from 'react';
import bemCn from 'bem-cn';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import useMobileScreen from '../../services/hooks/useMobileScreen';
import PictureSet from '../picture-set';
import Img1 from '../../assets/img/as-seen/p-fortune.png';
import Img2 from '../../assets/img/as-seen/p-gma.png?sizes[]=300';
import Img3 from '../../assets/img/as-seen/p-insider.png';
import Img4 from '../../assets/img/as-seen/p-money.png';
import Img5 from '../../assets/img/as-seen/p-nytimes.png';
import Img6 from '../../assets/img/as-seen/p-today.png';
import '@splidejs/splide/dist/css/splide.min.css';
import './style.scss';

const b = bemCn('sniff-as-seen');

const imgs = [
    { img: Img1, alt: 'Fortune' },
    { img: Img2, alt: 'Good Morning America' },
    { img: Img3, alt: 'Business Insider' },
    { img: Img4, alt: 'Money' },
    { img: Img5, alt: 'NYTimes' },
    { img: Img6, alt: 'Today Show' },
];

const Slides = () => {
    const isMobile = useMobileScreen();

    if (isMobile) {
        return imgs.map((item, index) => {
            return (
                <SplideSlide key={index}>
                    <div className={b('m-slider')}>
                        <PictureSet imgs={item.img} className={b('img')} alt={item.alt} width="auto" height={44} />
                    </div>
                </SplideSlide>
            );
        });
    }

    return [...Array(2)].map((_, index) => {
        return (
            <SplideSlide key={index}>
                <div className={b('slider')}>
                    {imgs.slice(index * 2 + index, index * 3 + index + 3).map((v, i) => (
                        <div key={i} className={b('slider-container')}>
                            <PictureSet imgs={v.img} className={b('img')} alt={v.alt} width="100%" height="auto" />
                        </div>
                    ))}
                </div>
            </SplideSlide>
        );
    });
};

const RenderServerComp = () => (
    <div className={b('seen-ssr')}>
        {imgs.map((img, idx) => (
            <div key={idx}>
                <PictureSet imgs={img.img} className={b('img')} alt={img.alt} width="auto" height={44} />
            </div>
        ))}
    </div>
);

const RenderClientComp = () => (
    <Splide options={{ focus: 'center', pagination: true, arrows: false }}>
        <Slides />
    </Splide>
);

const SniffAsSeen = ({ margin = 6 }) => {
    const [Component, setComponent] = useState(<RenderServerComp />);
    const marginPx = `${margin * 8}px`;

    useEffect(() => {
        setComponent(<RenderClientComp />);
    }, []);

    return (
        <div className={b()} style={{ marginTop: marginPx, marginBottom: marginPx }}>
            <p className="snif-p text-italic text-center">As seen on</p>
            {Component}
        </div>
    );
};

export default SniffAsSeen;
