import React from 'react';
import bemCn from 'bem-cn';
import michaelImg from '../../assets/img/about-michael.jpg';
import grishaImg from '../../assets/img/about-grisha.jpg';
import kimImg from '../../assets/img/kim-homepage.jpg';
import PictureSet from '../picture-set';
import './style.scss';

const b = bemCn('rec-experts');

const EXPERTS = [
    {
        img: kimImg,
        quote: `"The modern pet environment and lifestyle is seriously difficult for most dogs, given their
        natures, instincts, and heritage. Sniffspot is the greatest current solution to make a
        difference for dogs today."`,
        author: 'Kim Brophey',
        link: 'https://www.dogdoorcanineservices.com/kim-brophey/',
        subText: ", best-selling author, dog ethologist and creator of The Dog's Truth",
    },
    {
        img: grishaImg,
        quote: `"I think Sniffspot is a great idea! Dogs really need exercise in a safe space. Public exercise
        areas have a lot of risks for dogs with reactivity issues, so I'm excited to see this
        stress-relieving option."`,
        author: 'Grisha Stewart',
        link: 'https://grishastewart.com/',
        subText: ', trainer, speaker and creator of BAT',
    },
    {
        img: michaelImg,
        quote: `"With all of the restrictions that modern society places on dogs, it is not surprising to see
        behavior problems increase due to a lack of mental and physical enrichment. Many of the dogs I
        work with greatly benefit from a safe place that they can explore and run freely, to nurture all
        of their unique needs. Sniffspot is the perfect answer for dogs in our modern society!"`,
        author: 'Michael Shikashio CDBC',
        link: 'https://aggressivedog.com/',
        subText: ', world-Renowned Dog Aggression Specialist',
    },
];

const RecommendedByExperts = ({ useStateSSR, SSR }) => {
    return (
        <div className={b()}>
            {EXPERTS.map((v, i) => (
                <div key={i} className={b('our-experts')}>
                    <div>
                        <PictureSet
                            imgs={v.img}
                            alt={v.author}
                            width="120"
                            height="120"
                            useState={useStateSSR}
                            SSR={SSR}
                        />
                    </div>
                    <div>
                        <div className="snif-p snif-m1-pc snif-medium mb-2">{v.quote}</div>
                        <div className="snif-p snif-m2-pc snif-medium">
                            <a href={v.link} target="_blank" rel="noreferrer" className="about-link-text snif-semibold">
                                {v.author}
                            </a>
                            {v.subText}
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default RecommendedByExperts;
