import React from 'react';
import SvgCalendarClock from 'src/assets/svg/SvgCalendarClock.svg';
import SvgDog from 'src/assets/svg/SvgDog.svg';
import SvgHostSafety from 'src/assets/svg/SvgHostSafety.svg';
import SvgPaw from 'src/assets/svg/SvgPaw.svg';
import SvgSubscription from 'src/assets/svg/SvgSubscription.svg';
import { RouteFormatter } from '../routes';
import { SPOT_SRC_MEMBERSHIP } from './spot.consts';

export const LISTING_WORK_CONTENT = [
    {
        Icon: SvgCalendarClock,
        title: 'Private rentals by the hour',
        desc: () => <>Our hosts are locals that are renting their yards and private land to dog owners</>,
    },
    {
        Icon: SvgDog,
        title: 'Designed for private play',
        desc: () => <>Only the people and dogs in your booking are allowed in the spot during your visit</>,
    },
    {
        Icon: SvgHostSafety,
        title: 'Trust and safety first',
        desc: () => <>Spots are designed for safety and both hosts and guests are reviewed after each visit</>,
    },
    {
        Icon: SvgPaw,
        title: 'Reactive dogs welcome',
        desc: () => <>Many of the spots we list are designed with sensitive dogs in mind</>,
    },
    {
        Icon: SvgSubscription,
        title: 'Become a Sniffpass holder!',
        desc: () => (
            <>
                Get discounts on all bookings at all spots by buying monthly credits!
                <br />
                <a
                    href={RouteFormatter.subscriptionsNew(null, { query: { src: SPOT_SRC_MEMBERSHIP.WEB_HOMELP } })}
                    className="snif-semibold text-primary text-underline"
                >
                    Learn more!
                </a>
            </>
        ),
    },
];

export const LISTING_WORK_CONTENT_MBL = [
    LISTING_WORK_CONTENT[0],
    LISTING_WORK_CONTENT[2],
    LISTING_WORK_CONTENT[1],
    LISTING_WORK_CONTENT[3],
    LISTING_WORK_CONTENT[4],
];
